//
// Mobile Utility Menu
//

#mobile-utility-menu {
  box-shadow: 0px -20px 25px -5px rgba(0, 0, 0, 0.05);
  transition: all .3s ease-out;
  // transition-delay: 0.25s;

  body.scrolling & {
    transform: translateY(100%);
  }
}
