//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: 											#ffffff;

$gray-100: #EDEEEF;
$gray-200: #E1E2E3;
$gray-300: #CED1D2;
$gray-400: #B2B4B6;
$gray-500: #9B9D9E;
$gray-600: #686B6C;
$gray-700: #4E565B;
$gray-800: #434445;
$gray-900: #262F35;

$gray-100-dark:  #1b1b29 !default;
$gray-200-dark:  #2B2B40 !default;
$gray-300-dark:  #323248 !default;
$gray-400-dark:  #474761 !default;
$gray-500-dark:  #565674 !default;
$gray-600-dark:  #6D6D80 !default;
$gray-700-dark:  #92929F !default;
$gray-800-dark:  #CDCDDE !default;
$gray-900-dark:  #FFFFFF !default;

// Theme colors
// Neutrals
$neutral-75: #E8ECF2 !default;
$neutral-50: #F2F4FA !default;

$dark-blue-900: #003149 !default;
$dark-blue-500: #618596 !default;

// Blues
$blue-900: #00609C !default;
$blue-700: #006BAD !default;
$blue-500: #0077C0 !default;
$blue-300: #2591D3 !default;
$blue-200: #CCE4F2 !default;
$blue-100: #EEF7FC !default;
$blue-50: #F1FAFF !default;
$blue-25: #FBFDFF !default;

// Greens
$green-900: #36871F !default;
$green-500: #70BF55 !default;
$green-200: #E2F2DD !default;

// Purples
$purple-900: #59358C !default;
$purple-500: #7A59A9 !default;
$purple-200: #EAE4F2 !default;

// Yellows
$yellow-900: #FFCB00 !default;
$yellow-500: #FFE066 !default;
$yellow-200: #FFF5CC !default;

// Orange
$orange-900: #F68B33 !default;
$orange-500: #F8A25C !default;
$orange-200: #FDE8D6 !default;

// Primary
$primary:       									$blue-500;
$primary-active:    								$blue-700;
$primary-light:    								    #f1faff;
$primary-light-dark:    							#212e48;
$primary-inverse:  									$white;

// Secondary colors
$secondary: 					$gray-300 !default;

// Light colors
$light: 						$gray-100 !default;
$light-inverse:					$gray-700 !default;

// Success
$success:       									$green-500;
$success-active:    								#47be7d;
$success-light:    									$green-200;
$success-light-dark:    							#1c3238;
$success-inverse:  									$white;

// Info
$info:       									    #7239ea;
$info-active:    									#5014d0;
$info-light:    									#f8f5ff;
$info-light-dark:    								#2f264f;
$info-inverse:  									$white;

// Danger
$danger:       									    #CC1B00;
$danger-active:    									#CC1B00;
$danger-light:    									#fff5f8;
$danger-light-dark:    							#3a2434;
$danger-inverse:  									$white;

// Warning
$warning:       									#ffc700;
$warning-active:    								#f1bc00;
$warning-light:    									#fff8dd;
$warning-light-dark:    							#392f28;
$warning-inverse:  									$white;

// Bootstrap muted color
$text-muted: $gray-500 !default;
$text-muted-dark: $gray-500-dark !default;

// Dark
$dark: $dark-blue-900;

// Bootstrap gray colors map
$grays: (
  "100": $gray-100,
	"200": $gray-200,
	"300": $gray-300,
	"400": $gray-400,
	"500": $gray-500,
	"600": $gray-600,
	"700": $gray-700,
	"800": $gray-800,
	"900": $gray-900,
	"50": $neutral-50,
	"75": $neutral-75
) !default;

$blues: (
 "900": $blue-900,
 "700": $blue-700,
 "500": $blue-500,
 "300": $blue-300,
 "200": $blue-200,
 "100": $blue-100,
 "50": $blue-50,
 "25": $blue-25
) !default;

$greens: (
 "900": $green-900,
 "500": $green-500,
 "200": $green-200
) !default;

$purples: (
 "900": $purple-900,
 "500": $purple-500,
 "200": $purple-200
) !default;

// Text colors
$theme-text-colors: (
	"white":   $white,
	"primary": $primary,
	"secondary":  $secondary,
	"light":   $light,
	"success": $success,
	"info":    $info,
	"warning": $warning,
	"danger":  $danger,
	"dark":    $dark,
	"muted":   $text-muted,
	"gray-100":   $gray-100,
	"gray-200":   $gray-200,
	"gray-300":   $gray-300,
	"gray-400":   $gray-400,
	"gray-500":   $gray-500,
	"gray-600":   $gray-600,
	"gray-700":   $gray-700,
	"gray-800":   $gray-800,
	"gray-900":   $gray-900,
  "purple-900":   $purple-900,
  "orange-900":   $orange-900,
  "blue-500":   $blue-500,
  "blue-300":   $blue-300,
  "green-900":   $green-900
) !default;


// Typography
//
// Font, line-height, and color for body text, headings, and more.
// Font family
$font-family-sans-serif: "Karbon", sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `15px`
$font-size-lg: $font-size-base * 1.466666666666667; // 22px Body Large
$font-size-md: $font-size-base * 1.2; // 18px Body Medium
$font-size-sm: $font-size-base; // 15px Body Small
$font-size-xs: $font-size-base * 0.8666; // 13px Body Mini
$font-size-xxs: $font-size-base * 0.733333333333333; // 11px

$font-weight-normal:600 !default;
$font-weight-semibold: 700 !default;
$font-weight-bold: 800 !default;
$font-weight-bolder: 900 !default;

// Line heights
$line-height-base: 1.3 !default;
$line-height-sm: 1.3 !default;


// Heading sizes
$h1-font-size:  $font-size-base * 3.2; // 48px
$h2-font-size:  $font-size-base * 2.6; // 39px
$h3-font-size:  $font-size-base * 2.133333333333333; // 32px
$h4-font-size:  $font-size-base * 1.666666666666667; // 25px
$h5-font-size:  $font-size-base * 1.333333333333333; // 20px
$h6-font-size:  $font-size-base * 1.066666666666667; // 16px

$headings-line-height: 1.125;
$headings-color: $dark;

// Font Sizes
$font-sizes: (
	xxs: $font-size-xxs,
  xs: $font-size-xs,
	sm: $font-size-sm,
	md: $font-size-md,
	lg: $font-size-lg,

  1: $h1-font-size,   // 22.75px
	2: $h2-font-size,   // 19.50px
	3: $h3-font-size,   // 17.55px
	4: $h4-font-size,   // 16.25px
	5: $h5-font-size,   // 14.95px
	6: $h6-font-size,   // 13.95px

	7: $font-size-base * 0.95,  // 12.35px
	8: $font-size-base * 0.85,  // 11.05px
	9: $font-size-base * 0.75,  // 9.75px
	10: $font-size-base * 0.5,  // 6.50px

	base: $font-size-base, // 13px
	fluid: 100%, // 100%

	2x: $font-size-base * 2,  // 26px
	2qx: $font-size-base * 2.25, // 29.25px
	2hx: $font-size-base * 2.5,  // 32.5px
	2tx: $font-size-base * 2.75, // 35.75px

	3x: $font-size-base * 3,  // 39px
	3qx: $font-size-base * 3.25, // 42.25px
	3hx: $font-size-base * 3.5,  // 45.5px
	3tx: $font-size-base * 3.75, // 48.75px

	4x: $font-size-base * 4,  // 52px
	4qx: $font-size-base * 4.25, // 55.25px
	4hx: $font-size-base * 4.5,  // 58.5px
	4tx: $font-size-base * 4.75, // 61.75px

	5x: $font-size-base * 5,  // 65px
	5qx: $font-size-base * 5.25, // 68.25px
	5hx: $font-size-base * 5.5,  // 71.5px
	5tx: $font-size-base * 5.75  // 74.75px
);

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 16px;

// Components
//

// Card
$card-box-shadow: 0px 2px 4px rgba(38, 47, 53, 0.05);
$card-border-radius: 8px;
$card-py: 24px;
$card-px: 24px;
$card-header-py:                   					0 !default;
$card-header-height: 								29px !default;

// Dropdowns
$dropdown-border-radius: 8px;
$dropdown-box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.1);

// Keenthemes menu component
$menu-link-padding-y: 0.8rem;

// Sidebar base
$app-sidebar-base-z-index-mobile: 1040 !default;

// Sidebar light
$app-sidebar-light-menu-link-bg-color-active: rgba(#EDEEEF, 0.5);

// Keenthemes drawer component
$drawer-overlay-bg-color: rgba($gray-900, 0.5);

// Keenthemes scrollbar component
$scrollbar-width: 5px;

// Links
//
// Style anchor elements.
$link-color:                $blue-500;
$link-hover-color:          $blue-900;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y:                      0.49rem;
$input-btn-font-size:                       $font-size-base * 1.066666666666667;
$input-btn-line-height:                     $line-height-base * 1.153846153846154;

$btn-font-weight:                           $font-weight-bold !default;

$btn-transition:                            all .15s ease-in-out !default;

// Forms
$form-text-color:                           $gray-700 !default;
$input-color:                               $gray-900 !default;
$form-label-margin-bottom:                  2px !default;
$form-label-font-weight:                    700 !default;
$form-label-font-size:                      15px !default;
$input-border-radius:                       4px !default;
$input-box-shadow:                        unset;

$input-font-weight:                         $font-weight-normal !default;

$input-focus-border-color:                  $blue-300 !default;
$input-focus-width:                        	2.5px !default;
$input-focus-box-shadow:                    0 0 0 2.5px rgb(0 119 192 / 25%);

$form-select-font-size: $font-size-sm;

$form-select-font-size-sm:                 $font-size-sm;
$form-select-padding-y-sm:                0.4rem;
$form-select-border-radius-sm: 4px;

// Form checks
$form-check-input-width:                    1.1rem !default;
$form-check-input-border-radius:            .15em !default;
$form-check-input-border:                   1px solid $gray-500 !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding-x: .5rem !default;

$table-hover-bg:                            $blue-25 !default;

// Modals
$modal-md: 560px;


// Pagination
$pagination-item-height:                            2.3rem !default;
$pagination-font-size:                              1.1rem !default;

$pagination-color:                                  $gray-600 !default;
$pagination-bg:                                     transparent !default;
$pagination-border-width:                           0 !default;

$pagination-active-color:                           $primary !default;
$pagination-active-bg:                              transparent !default;

// Tooltips
$tooltip-max-width:                 250px;
$tooltip-padding-y:                 0;
$tooltip-padding-x:                 0;