@font-face {
  font-family: "Karbon";
  src: url("../../../fonts/karbon-regular-webfont.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Karbon";
  src: url("../../../fonts/karbon-medium-webfont.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Karbon";
  src: url("../../../fonts/karbon-semibold-webfont.woff") format("woff");
  font-weight: 800;
}
