//
// Sidebar
//

// Desktop mode
@include media-breakpoint-up(lg) {
    .app-sidebar-toggle {
        box-shadow: var(--kt-app-sidebar-base-toggle-btn-box-shadow) !important;
        background-color: var(--kt-app-sidebar-base-toggle-btn-bg-color) !important;
    }

    .app-sidebar-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-shrink: 0;
    }

    .app-sidebar-menu {
        .menu  {
            & > .menu-item {
                margin-left: 0.115rem;
            }
        }
    }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
    .app-sidebar-logo {
        // display: none;
    }
}