//
// Custom Header(used by Light Sidebar layout only)
//

[data-kt-app-layout="light-sidebar"] {
    .app-header {
        //border-bottom: 1px solid var(--kt-app-sidebar-light-separator-color);

        @include media-breakpoint-up(lg) {
          box-shadow: none;
        }
    }

    // Desktop mode
    @include media-breakpoint-up(lg) {
        .app-header-menu {
            .menu {
                // Menu root item
                > .menu-item {
                    @include menu-link-here-state(
                        $title-color: var(--kt-primary),
                        $icon-color: var(--kt-primary),
                        $bullet-color: var(--kt-primary),
                        $arrow-color: var(--kt-primary),
                        $bg-color: var(--app-sidebar-light-header-menu-link-bg-color-active),
                        $all-links: false
                    );

                    @include menu-link-active-state(
                        $title-color: var(--kt-primary),
                        $icon-color: var(--kt-primary),
                        $bullet-color: var(--kt-primary),
                        $arrow-color: var(--kt-primary),
                        $bg-color: var(--app-sidebar-light-header-menu-link-bg-color-active),
                        $all-links: false
                    );
                }
            }
        }
    }
}