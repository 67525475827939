//
// Interactive Pricing Matrix
//

#zoom-controls {
  right: 18px;
  bottom: 22px;
  position: fixed;
  z-index: 20;

  @include media-breakpoint-down(md) {
    display: none;
  }

  .btn {
    background-color: $white;

    &:hover {
      background-color: $primary;
      color: $white;
    }

    &:active {
      background-color: darken($primary, 7);
    }
  }
}

#tooth-selection-detail-panel {
  left: 0;
  bottom: 0;
  height: 40%;
  top: unset;
  width: 100%;
  visibility: visible;
  transition: height 0.3s ease, transform 0.3s ease;

  @include media-breakpoint-up(xl) {
    height: 100vh;
    width: 400px;
  }

  &.expand {
    @include media-breakpoint-down(xl) {
      height: 100%;
    }
  }

  &:not(.showing, .show),
  &.hiding {
    transform: translateY(100%);

    @include media-breakpoint-up(xl) {
      transform: translateX(-100%);
    }
  }

  // Colour Selection add dropdown button
  .colour-selection-add {
    color: $dark;

    &:hover {
      color: $primary;
    }
  }

  // Dropdown wrap
  .colour-selection-dropdown-wrap {
    .colour-selection-dropdown-inner-wrap {
      max-width: 188px;
    }

    .dropdown-menu {
      padding: 18px 14px;
      border: 1px solid #e8ecf2;
      box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      width: 100%;
      flex-wrap: wrap;
    }

    .dropdown-menu.show {
      display: flex;
    }

    .dropdown-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: unset;
      padding: 5px;
      font-size: 13px;

      &:hover {
        color: $primary;
        background: unset;
        cursor: pointer;

        .colour {
          border-color: $primary;
        }
      }

      .colour {
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 100px;
        border: 1px solid transparent;
        margin-bottom: 4px;
        transition: border-color 0.3s ease;
      }
    }

    .colour-selection-clear {
      display: none;
    }

    &.visible {
      display: block !important;
    }

    &.last-visible {
      .colour-selection-clear {
        display: block !important;
      }

      .colour-selection-add {
        display: block !important;
      }
    }
  }
}

// Tooth colour diagram
#colour-selection-wrap {
  #tooth-colour-selections-diagrams {
    position: relative;
  }

  .tooth-colour-selection-diagram {
    display: none;

    &.visible {
      display: block;
    }
  }

  .colour-selection-name {
    position: absolute;
    font-size: 13px;
    line-height: 20px;
    top: 47%;
    bottom: 0;
    margin: auto;
    left: 43%;
  }

  .two-colours {
    // Set position for colour names when 2 colours selected
    .colour-selection-name:nth-child(1) {
      top: 23%;
    }
    .colour-selection-name:nth-child(2) {
      top: 66%;
    }
  }

  .three-colours {
    // Set position for colour names when 3 colours selected
    .colour-selection-name:nth-child(1) {
      top: 9%;
    }
    .colour-selection-name:nth-child(2) {
      top: 43%;
    }
    .colour-selection-name:nth-child(3) {
      top: 80%;
    }
  }
}

#tooth-selection-expand {
  border: none;
  background: none;
  margin-left: auto;
  margin-right: 0.75rem;
  border: 1px solid var(--kt-primary);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  padding: 0;

  &:before {
    content: "+";
    display: block;
    height: 20px;
    width: 20px;
    font-size: 31px;
    line-height: 18px;
    color: var(--kt-primary);
    text-align: center;
  }
}

.expand #tooth-selection-expand:before {
  content: "-";
}

#help-btn {
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.05);
  position: absolute;
  top: -55px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  padding: 0;
  flex-shrink: 0;
  left: 15px;

  &:hover {
    background-color: darken($primary, 7);
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    right: -56px;
    left: unset;
    top: unset;
    bottom: 16px;
  }
}

// Teeth
#teeth-diagram {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    max-height: 100vh;
    max-width: 100vw;
    height: calc(100vh - 56px);
    width: auto;
    margin: 0;
    overflow: visible !important;

    @include media-breakpoint-up(lg) {
      max-height: calc(100vh - 56px);
      margin-top: 56px;
    }
  }

  .tooth {
    &:hover {
      cursor: pointer;
    }
  }

  .selection {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .tooth:hover .selection {
    opacity: 0.4;
  }

  .tooth.active .selection {
    opacity: 1;
  }

  .tooth:hover .number path {
    fill: var(--kt-body-color);
    stroke: var(--kt-body-color);
    stroke-width: 1px;
    stroke-linejoin: round;
    paint-order: stroke;
  }
  .tooth.active .number path {
    fill: var(--kt-primary);
    stroke: var(--kt-primary);
    stroke-width: 1px;
    stroke-linejoin: round;
    paint-order: stroke;
  }
}

#tooth-selection-heading {
  word-break: break-word;
}
