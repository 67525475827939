//
// App Navbar
//

.app-navbar {
    display: flex;
    align-items: stretch;

    .app-navbar-item {
        display: flex;
        align-items: center;
    }

    &.app-navbar-stretch {
        .app-navbar-item {
            align-items: stretch;
        }
    }
}

.app-navbar-bell {
  .badge {
    width: 15px;
    height: 15px;
    background-color: #D75454;
    justify-content: center;
    right: -4px;
    top: 10px;
    z-index: 2;

    .notifications {
      width: 15px;
      height: 15px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      position: relative;
      top: -0.5px;
    }
  }

  svg {
    top: 2px;
    position: relative;
  }

  @include media-breakpoint-up(lg) {
    &:hover svg {
      color: $dark-blue-900;
    }
  }
}

.app-navbar-profile {
  color: inherit;

  .symbol {
    width: 24px;
    height: 24px;
    color: $dark-blue-900;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}