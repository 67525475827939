
// The dropdown menu
.dropdown-menu {
	--#{$prefix}dropdown-color: var(--kt-dropdown-color);
	--#{$prefix}dropdown-bg: var(--kt-dropdown-bg);
	--#{$prefix}dropdown-border-color: var(--kt-dropdown-border-color);
	--#{$prefix}dropdown-divider-bg: var(--kt-dropdown-divider-bg);
	--#{$prefix}dropdown-box-shadow: var(--kt-dropdown-box-shadow);
	--#{$prefix}dropdown-link-color: var(--kt-dropdown-link-color);
	--#{$prefix}dropdown-link-hover-color: var(--kt-dropdown-link-hover-color);
	--#{$prefix}dropdown-link-hover-bg: var(--kt-dropdown-link-hover-bg);
	--#{$prefix}dropdown-link-active-color: var(--kt-dropdown-link-active-color);
	--#{$prefix}dropdown-link-active-bg: var(--kt-dropdown-link-active-bg);
	--#{$prefix}dropdown-link-disabled-color: var(--kt-dropdown-link-disabled-color);
	--#{$prefix}dropdown-header-color: var(--kt-dropdown-header-color);
}

// Dropdowns styled as form select element
//

.form-control-dropdown {
  display: flex;
  align-items: center;
  padding-right: 0.7rem;
  flex-shrink: 0;

  &:after {
    margin-left: auto;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 7.5L10 12.5L15 7.5' stroke='%23003149' stroke-linecap='square'/%3E%3C/svg%3E%0A");    border: none;
  }
}