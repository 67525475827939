//
// sidebar
//

// General mode
.app-header {
    transition: $app-header-base-transition;
    display: flex;
    align-items: stretch;
    z-index: 3;

    // inherit color from app-header so can control button colours on different header variations
    .btn {
      color: inherit;
    }
}

// Desktop mode
@include media-breakpoint-up(lg) {
    // Base
    .app-header {
        @include property( z-index, $app-header-base-z-index);

        background-color: transparent;
        box-shadow: var(--kt-app-header-base-box-shadow);
        border-bottom: var(--kt-app-header-base-border-bottom);
        position: absolute;
        color: var(--kt-body-color);

    }

    // Vars
    :root {
        --kt-app-header-height: #{$app-header-base-height};
        --kt-app-header-height-actual: #{$app-header-base-height};
    }

    [data-kt-app-header-sticky="on"] {
        --kt-app-header-height: #{$app-header-sticky-height};
        --kt-app-header-height-actual: #{$app-header-base-height};
    }

    [data-kt-app-header-sticky="on"][data-kt-app-header-stacked="true"] {
        --kt-app-header-height: calc(var(--kt-app-header-primary-height, 0px) + var(--kt-app-header-secondary-height, 0px));
        --kt-app-header-height-actual: calc(#{$app-header-primary-base-height} + #{$app-header-secondary-base-height});
    }

    [data-kt-app-header-minimize="on"] {
        --kt-app-header-height: #{$app-header-minimize-height};
    }

    // States
    .app-header {
        height: var(--kt-app-header-height);

        [data-kt-app-header-fixed="true"] & {
            @include property( z-index, $app-header-fixed-z-index);
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
        }

        [data-kt-app-header-static="true"] & {
            position: relative;
        }

        [data-kt-app-header-stacked="true"] & {
            flex-direction: column;
            height: calc(var(--kt-app-header-primary-height) + var(--kt-app-header-secondary-height, 0px));
        }

        [data-kt-app-header-sticky="on"] & {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            @include property( z-index, $app-header-sticky-z-index);

            background-color: var(--kt-app-header-sticky-bg-color);
            box-shadow: var(--kt-app-header-sticky-box-shadow);
            border-bottom: var(--kt-app-header-sticky-border-bottom);
        }

        [data-kt-app-header-minimize="on"] & {
            transition: $app-header-base-transition;
            @include property( z-index, $app-header-minimize-z-index);

            background-color: var(--kt-app-header-minimize-bg-color);
            box-shadow: var(--kt-app-header-minimize-box-shadow);
            border-bottom: var(--kt-app-header-minimize-border-bottom);
        }

        .app-header-mobile-drawer {
            display: flex;
        }

        // When desktop size and header is not set to transparent
        [data-app-header-transparent="false"] & {
          background-color: $white;
          box-shadow: 0px 4px 8px rgba(38, 47, 53, 0.05) !important;
          border: 1px solid $gray-100;
        }

        // When desktop size and header is set to transparent
        [data-app-header-transparent="true"] & {
          color: $white;
        }
    }

    // Integration
    .app-header {
        // Sidebar
        [data-kt-app-header-fixed="true"][data-kt-app-sidebar-fixed="true"][data-kt-app-sidebar-push-header="true"] &,
        [data-kt-app-header-fixed="true"][data-kt-app-sidebar-sticky="on"][data-kt-app-sidebar-push-header="true"] & {
            left: calc(
                var(--kt-app-sidebar-width) +
                var(--kt-app-sidebar-gap-start, 0px) +
                var(--kt-app-sidebar-gap-end, 0px)
            );
        }

        body:not([data-kt-app-header-fixed="true"])[data-kt-app-sidebar-fixed="true"][data-kt-app-sidebar-push-header="true"] &,
        body:not([data-kt-app-header-fixed="true"])[data-kt-app-sidebar-sticky="on"][data-kt-app-sidebar-push-header="true"] & {
            margin-left: calc(
                var(--kt-app-sidebar-width) +
                var(--kt-app-sidebar-gap-start, 0px) +
                var(--kt-app-sidebar-gap-end, 0px)
            );

            width: calc(
              100% -
              var(--kt-app-sidebar-width) -
              var(--kt-app-sidebar-gap-start, 0px) -
              var(--kt-app-sidebar-gap-end, 0px)
            );
        }

        // Sidebar Panel
        [data-kt-app-header-fixed="true"][data-kt-app-sidebar-panel-fixed="true"][data-kt-app-sidebar-panel-push-header="true"] &,
        [data-kt-app-header-fixed="true"][data-kt-app-sidebar-panel-sticky="on"][data-kt-app-sidebar-panel-push-header="true"] & {
            left: calc(
                var(--kt-app-sidebar-width) +
                var(--kt-app-sidebar-gap-start, 0px) +
                var(--kt-app-sidebar-gap-end, 0px) +
                var(--kt-app-sidebar-panel-width) +
                var(--kt-app-sidebar-panel-gap-start, 0px) +
                var(--kt-app-sidebar-panel-gap-end, 0px)
            );
        }

        body:not([data-kt-app-header-fixed="true"])[data-kt-app-sidebar-panel-fixed="true"][data-kt-app-sidebar-panel-push-header="true"] &,
        body:not([data-kt-app-header-fixed="true"])[data-kt-app-sidebar-panel-sticky="on"][data-kt-app-sidebar-panel-push-header="true"] & {
            margin-left: calc(
                var(--kt-app-sidebar-width) +
                var(--kt-app-sidebar-gap-start, 0px) +
                var(--kt-app-sidebar-gap-end, 0px) +
                var(--kt-app-sidebar-panel-width) +
                var(--kt-app-sidebar-panel-gap-start, 0px) +
                var(--kt-app-sidebar-panel-gap-end, 0px)
            );
        }

        // Toolbar
        [data-kt-app-header-fixed="true"][data-kt-app-toolbar-fixed="true"] & {
            box-shadow: none;
        }
    }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
    // Base
    .app-header {
        @include property( z-index, $app-header-base-z-index-mobile);

        background-color: var(--kt-app-header-base-bg-color-mobile);
        box-shadow: var(--kt-app-header-base-box-shadow-mobile);
        border-bottom: 0;
    }

    // Vars
    :root {
        --kt-app-header-height: #{$app-header-base-height-mobile};
    }

    [data-kt-app-header-sticky="on"] {
        --kt-app-header-height: #{$app-header-sticky-height-mobile};
        --kt-app-header-height-actual: #{$app-header-sticky-height-mobile};
    }

    [data-kt-app-header-minimize="on"] {
        --kt-app-header-height: #{$app-header-minimize-height-mobile};
        --kt-app-header-height-actual: #{$app-header-minimize-height-mobile};
    }

    // States
    .app-header {
        height: var(--kt-app-header-height);
        align-items: stretch;

        .app-header-mobile-drawer {
            display: none;
        }

        [data-kt-app-header-stacked="true"] & {
            flex-direction: column;
        }

        [data-kt-app-header-fixed-mobile="true"] & {
            @include property( z-index, $app-header-fixed-z-index-mobile);
            transition: $app-header-base-transition;
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
        }

        [data-kt-app-header-sticky="on"] & {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            @include property( z-index, $app-header-sticky-z-index-mobile);

            background-color: var(--kt-app-header-sticky-bg-color-mobile);
            box-shadow: var(--kt-app-header-sticky-box-shadow-mobile);
            border-bottom: var(--kt-app-header-sticky-border-bottom-mobile);
        }

        [data-kt-app-header-minimize="on"] & {
            @include property( z-index, $app-header-minimize-z-index-mobile);
            transition: $app-header-base-transition;
            background-color: var(--kt-app-header-minimize-bg-color-mobile);
            box-shadow: var(--kt-app-header-minimize-box-shadow-mobile);
            border-bottom: var(--kt-app-header-minimize-border-bottom-mobile);
        }
    }

    // Integration
    .app-header {
        // Toolbar
        [data-kt-app-header-fixed-mobile="true"][data-kt-app-toolbar-fixed-mobile="true"] & {
            box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.05);
            background: $white;
        }

        [data-kt-app-header-fixed-mobile="true"][data-kt-app-toolbar-sticky="on"] & {
            box-shadow: none;
        }
    }
}