//
// Assessment Photos Grid
//
.assessment-photo-card {
  width: 96px;
  position: relative;
  margin-bottom: 20px;

  @include media-breakpoint-up(sm) {
    width: 128px;
    margin-bottom: 37px;
  }

  .icon-badge {
    position: absolute;
    top: -8px;
    right: -13px;
    width: 32px;
    height: 32px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;

    svg {
      position: absolute;
    }

    .icon-check {
      display: none;
    }
  }

  &.file_uploaded .icon-check {
    display: block;
  }

  &.file_uploaded .icon-camera {
    display: none;
  }

  .button_outer {
    background: #83ccd3;
    border-radius: 30px;
    text-align: center;
    height: 50px;
    width: 200px;
    display: inline-block;
    transition: 0.2s;
    position: relative;
    overflow: hidden;
  }

  .upload_file {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  .processing_bar {
    position: absolute;
    left: 8px;
    top:0;
    bottom: 0;
    margin: auto;
    width: calc(100% - 16px);
    border-radius: 30px;
    background: $gray-500;
    overflow: hidden;
    height: 4px;
    opacity: 0;

    .processing_bar_progress {
      width: 0;
      background: $primary;
      height: 4px;
      transition: 3s;
    }
  }

  &.file_uploading .processing_bar {
    opacity: 1;

    .processing_bar_progress {
      width: 100%;
    }
  }

  .success_box {
    display: none;
    width: 50px;
    height: 50px;
    position: relative;
  }

  .error_msg {
    text-align: center;
    color: #f00;
    line-height: 1;
  }

  .uploaded_file_view {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    transition: 0.2s;
    opacity: 0;

    &.show {
      opacity: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .file_remove {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: none;
    position: absolute;
    background: #fb7575;
    line-height: 30px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    left: -9px;
    top: -7px;
    align-items: center;
    justify-content: center;
    z-index: 20;

    svg {
      width: 10px;
    }
  }

  &.file_uploaded .file_remove {
    display: flex;
  }
}

.assessment-photo-card-image-wrap {
  background-color: $neutral-50;
  border-radius: 8px;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;

  .avatar {
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity .3s ease;
    opacity: 1;
  }
}

.file_uploading .avatar {
  opacity: 0;
}

.file_uploaded .avatar {
  opacity: 0;
}