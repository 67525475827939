//
// Labels
//

.form-label {
	color: var(--kt-form-label-color);
  line-height: 24px;
}

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label {
	color: var(--kt-form-label-color);
}
