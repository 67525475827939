//
// Floating button. Appears at bottom of screen when scrolled past fold
//
.floating-button {
  transition: none;

  body.scrolled-fold & {
    bottom: 82px;
    right: 16px;
    z-index: 99;
    animation-name: floatingButton;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-direction: normal;
    animation-fill-mode: both;
    opacity: 1;

    @include media-breakpoint-down(sm) {
      height: 40px;
      width: 40px;
      padding: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include media-breakpoint-up(xl) {
      bottom: 32px;
    }


    .btn-text {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .svg-icon {
      @include media-breakpoint-down(sm) {
        margin-right: 0 !important;
      }
    }
  }

}

@keyframes floatingButton {
  0% {
    opacity: 1;
    position: static;
  }

  49% {
    opacity: 0;
    position: static;
  }

  50% {
    position: fixed;
    opacity: 0;
  }

  51% {
    opacity: 0;
    position: fixed;
  }

  100% {
    opacity: 1;
    position: fixed;
  }
}